import React, { useState, useEffect } from "react"
// import axios from "axios"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import { StripeProvider } from "react-stripe-elements"
import { Stripe } from "stripe.js"
import Checkout from "../../components/checkout"
import Instalments from '../../components/instalments.js'

// import Devstar from '../../pics/be_a_devstar.jpg'

import Contacts from "../../components/contactform"

const JSprogrammingOnline = () => {
	let Devstar = "https://barcelonacodeschool.com/files/pics/be_a_devstar.jpg"

	return (
		<div className="flexContent">
		<Helmet
		title="Programming with JavaScript online course in Barcelona Code School"
		meta={[
		{
			name: "description",
			content:
			"Programming with JavaScript is a 4-week online programming course from Barcelona Code School. This highly practical training aimed at the beginners which will take them to the advanced level and give a solid understanding of logic, syntax and usage of JavaScript. This course is a part of the JavaScript Full-Stack Bootcamp and could be taken separately as a module.",
		},
		{
			name: "keywords",
			content:
			"programming with javascript course, javascript course, javascript programming in barcelona",
		},
		{
			name: "viewport",
			content: "width=device-width, initial-scale=1",
		},
		]}
		script={[{ src: "https://js.stripe.com/v3/" }]}
		/>
		<h1 className='transparentContainer flex1200'>Programming with JavaScript <span className="highlighter">online mentored</span> course in Barcelona Code School</h1>
		
		<div className="fulltimeOnlineBanner flex1200">
		<p className="stamp">ONLINE</p>
		<img
		style={{ width: "99%" }}
		className="banners offsetImage"
		src={Devstar}
		alt="JavaScript programming course in Barcelona Code School"
		/>
		</div>

		<h2 className='transparentContainer flex450'>Learn logic, syntax and usage of JavaScript!</h2>
		
		
		<h2 className='transparentContainer flex225'><span className="highlighter">4 weeks</span>, part-time, online, in ENglish</h2>
		

		<article className='whiteCard flex300'>
		<ul>
		<li>
		<span className="highlighter">4 weeks</span> of online
		hands-on training
		</li>
		<li>4 personal 1:1 sessions with your mentor</li>
		<li>24/7 acces to support platform</li>
		<li>13 unique video-lessons</li>
		<li>More than 90 coding challenges to complete</li>
		<li>All the materials are in English</li>
		<li>From beginner to advanced level</li>
		<li>Learn the 'language of the web' in one go</li>
		<li>6 months access to the materials</li>
		<li>Certificate of completion</li>
		</ul>
		</article>
		

		<article className="transparentContainer flex450">
		<h2>What you'll learn</h2>
		<div className='whiteCard'>
		<ul>
		<li>Programming logic</li>
		<li>Variables</li>
		<li>Comparison operators</li>
		<li>Built-in methods</li>
		<li>Data types</li>
		<li>Arrays</li>
		<li>Strings</li>
		<li>Loops</li>
		<li>Conditionals</li>
		<li>Functions</li>
		<li>Objects</li>
		<li>ES6 syntax, recursion, scope</li>
		<li>Classes, prototypes, inheritance</li>
		<li>Constructors</li>
		<li>Array methods</li>
		<li>Practicing advanced JavaScript</li>
		</ul>
		</div>
		</article>

		<article className='transparentContainer flex225'>
		<h2>The outcomes</h2>
		<div className='whiteCard'>
		<p>
		After this course you will have a strong understanding of
		the <strong>logic</strong>, <strong>syntax</strong> and{" "}
		<strong>usage</strong> of pure JavaScript.{" "}
		</p>
		<p>
		You will be ready to move on to add these new skills to your
		web applications and continue further learnig by moving into
		front-end or back-end web development.
		</p>
		<p>
		Ability to solve programming challenges with JS by splitting
		them into logical steps and using algorithms for finding the
		best solution.
		</p>
		</div>
		</article>

		<article className='transparentContainer flex225'>
		<h2>Tuition</h2>
		<div className='whiteCard'>
		<h2>1200€</h2>
		<p>Single payment, paid upon registration, start immediately!.</p>
		<Instalments/>
		</div>
		</article>

		<article className='transparentContainer flex450'>
		<h2>How it works</h2>
		<div className='whiteCard'>
		<ul>
		<li>Sign up through this page</li>
		<li>Fill out student's form we will send you</li>
		<li>
		Get the materials including texts, videos and exercises with
		testing platform
		</li>
		<li>Start learning</li>
		<li>
		Access support platform 24/7 to post code-related questions
		and get help
		</li>
		<li>Book your 1:1 sessions from our calender</li>
		<li>
		Go through the lectures and complete exercises for each
		block of the curriculum
		</li>
		<li>Test your solutions with the provided automatic tests</li>
		<li>Push your code to GitLab to get feedback/review from us</li>
		<li>Get yourt certificate once finished</li>
		</ul>
		</div>
		</article>


		<article id="cohorts" className="transparentContainer flex900 flexContent">
		<h2 id="dates">Access</h2>
		<div>
		<div className="whiteCard">
		<span>
		<h2>
		Programming with JavaScript{" "}
		<span className="highlighter">online</span> course
		</h2>
		<p>
		4 weeks{" "}
		<span className="highlighter">mentored</span>,
		part-time, online
		</p>
		</span>
		<span>
		<Link
		to="/register"
		state={{
			course: "Programming with JS online course",
			date: new Date().toLocaleDateString(),
			cost: 1200,
			images: [Devstar],
		}}
		>
		<button style={{ marginBottom: "1em" }}>
		Sign up
		</button>
		</Link>
		<p className="finePrint">
		By signing up you agree to the{" "}
		<a href="/about-us/terms-and-conditions/">
		Terms and Conditions
		</a>{" "}
		stated on the website.
		</p>
		</span>
		</div>
		</div>
		</article>
		<div className='flex450'>
		<Contacts
		title={"Inquiry about the course"}
		subject={"Inquiry about Programming with JS online course"}
		/>
		</div>
		</div>
		)
}

export default JSprogrammingOnline
